import React from 'react';
import Layout from '../components/layout';
import { graphql, StaticQuery } from 'gatsby';
import Flickity from 'react-flickity-component';
import Footer from '../components/footer';

const flickityOptions = {
  initialIndex: 0,
  autoPlay: 4500,
  pauseAutoPlayOnHover: true,
  accessibility: true,
  wrapAround: true,
  disableImagesLoaded: false,
  elementType: 'div',
  reloadOnUpdate: false,
  static: false,
};

export default ({ pageContext }) => (
  <StaticQuery
    query={graphql`
      {
        allWordpressPage(filter: { title: { eq: "Gallery" } }) {
          edges {
            node {
              acf {
                gallery {
                  source_url
                }
              }
            }
          }
        }
      }
    `}
    render={(props) => (
      <Layout>
        <div className='page-title'>
          <h1 dangerouslySetInnerHTML={{ __html: pageContext.title }} />
        </div>
        <Flickity
          className={'carousel carousel-main carousel-gallery desktop-gallery'}
          options={flickityOptions}
        >
          {props.allWordpressPage.edges[0].node.acf.gallery.map((image) => (
            <div className='carousel-cell'>
              <img src={image.source_url} alt='Hero' />
            </div>
          ))}
        </Flickity>
        <div className='mobile-gallery'>
          {props.allWordpressPage.edges[0].node.acf.gallery.map((image) => (
            <div className='mobile-images'>
              <img src={image.source_url} alt='Mobile Gallery' />
            </div>
          ))}
        </div>
        <Footer />
      </Layout>
    )}
  />
);
